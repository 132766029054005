import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useTranslation } from 'react-i18next';

function createData(name, mkt, smb, hml, rmw, cma, wml, alpha, r) {
  return { name, mkt, smb, hml, rmw, cma, wml, alpha, r };
}

const rows = [
  createData('factorinvesting.eu', '0.99 (0.00)', '0.88 (0.00)', '0.40 (0.00)', '0.43 (0.03)', '0.14 (0.49)', '0.16 (0.06)', '0.18 (0.43)', '94%'),
  createData('Indépendance et Expansion Europe Small A(C)', '1.09 (0.00)', '0.83 (0.00)', '0.52 (0.00)', '0.63 (0.02)', '-0.02 (0.89)', '0.18 (0.14)', '0.41 (0.21)', '91%'),
  createData('SPDR MSCI Europe Small Cap Value Weighted', '1.19 (0.00)', '0.77 (0.00)', '0.35 (0.00)', '0.11 (0.50)', '0.23 (0.18)', '-0.04 (0.54)', '0.18 (0.34)', '97%'),
  createData('iShares MSCI EMU Small Cap', '1.21 (0.00)', '0.55 (0.00)', '0.35 (0.00)', '0.06 (0.70)', '0.04 (0.82)', '0.11 (0.10)', '-0.26 (0.17)', '97%'),
  createData('MSCI EMU Micro Cap', '1.00 (0.00)', '0.94 (0.00)', '0.16 (0.26)', '-0.08 (0.70)', '0.04 (0.86)', '0.03 (0.72)', '-0.36 (0.17)', '94%'),
  createData('Placeuro Abacus Discovery I', '0.93 (0.00)', '0.96 (0.00)', '0.22 (0.28)', '0.02 (0.95)', '-0.22 (0.52)', '0.21 (0.12)', '0.24 (0.53)', '86%'),
];

export default function RegressionTable() {
  const { t } = useTranslation();
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>{t('name')}</TableCell>
            <TableCell align="right">Mkt-RF</TableCell>
            <TableCell align="right">SmB</TableCell>
            <TableCell align="right">HmL</TableCell>
            <TableCell align="right">RmW</TableCell>
            <TableCell align="right">CmA</TableCell>
            <TableCell align="right">WmL</TableCell>
            <TableCell align="right">α</TableCell>
            <TableCell align="right">R<sup>2</sup></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCell align="right">{row.mkt}</TableCell>
              <TableCell align="right">{row.smb}</TableCell>
              <TableCell align="right">{row.hml}</TableCell>
              <TableCell align="right">{row.rmw}</TableCell>
              <TableCell align="right">{row.cma}</TableCell>
              <TableCell align="right">{row.wml}</TableCell>
              <TableCell align="right">{row.alpha}</TableCell>
              <TableCell align="right">{row.r}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
